import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { saveAs } from 'file-saver';

import Doc from '../../documents/ENVEL PRIVACY POLICY_Dec 2021_20211207.pdf';
import Layout from '../../components/Layout';
import MetaData from '../../components/meta/MetaData';
import { Body, CrowdLink } from '../../styles';

const Download = ({ location }) => {
	useEffect(() => {
		saveAs(Doc, 'ENVEL PRIVACY POLICY_Dec 2021_20211207.pdf');
	}, []);

	const downloadFile = () => {
		saveAs(Doc, 'ENVEL PRIVACY POLICY_Dec 2021_20211207.pdf');
	};

	return (
		<Layout>
			<MetaData
				title="Envel - Privacy Policy December 2021"
				description="Envel Privacy Policy December 2021"
				image="images/social/social.png"
				location={location}
			/>

			<div style={{ height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Body center style={{ width: '90%' }}>
					Thank you for downloading our Privacy Policy December 2021. If it did not download automatically, please click{' '}
					<CrowdLink onClick={downloadFile}>here</CrowdLink>
					.
					<br />
					<br /> Return <Link to="/">home.</Link>
				</Body>
			</div>
		</Layout>
	);
};

export default Download;
